import React from "react"

export const Photo = () => (
  <svg viewBox="0 0 106 76" xmlns="http://www.w3.org/2000/svg">
    <path d="M97.4385 12.1125H80.2389L69.9447 2.4225C69.9447 2.4225 69.8938 2.375 69.8683 2.375L69.8173 2.3275C68.2885 0.902501 66.2245 0 63.8803 0H42.4764C39.9793 0 37.7625 0.9975 36.2082 2.58875V2.6125L26.1433 12.1125H8.56154C3.82211 12.1125 0 15.58 0 19.9975V67.9962C0 72.4137 3.82211 76 8.56154 76H97.4385C102.152 76 106 72.4137 106 67.9962V19.9975C106 15.58 102.152 12.1125 97.4385 12.1125ZM95.8332 24.8188C93.8712 24.8188 92.2659 23.3225 92.2659 21.47C92.2659 19.6175 93.8712 18.1213 95.8332 18.1213C97.7952 18.1213 99.4005 19.6175 99.4005 21.47C99.4005 23.3225 97.7952 24.8188 95.8332 24.8188Z" />
  </svg>
)

export const Arrow = ({ previous, size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    transform={previous ? "rotate(0)" : "rotate(180)"}
    viewBox="0 0 24 24"
    display="block"
  >
    <path fill="#733A96" d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
)

export const Menu = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
  </svg>
)

export const Certifcation = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path d="M14.969 13.547l.031.191c0 .193-.096.379-.264.496-.538.372-.467.278-.67.885-.084.253-.33.424-.605.424h-.002c-.664-.002-.549-.038-1.083.338-.112.08-.244.119-.376.119s-.264-.039-.376-.118c-.534-.376-.419-.34-1.083-.338h-.002c-.275 0-.521-.171-.605-.424-.203-.607-.133-.513-.669-.885-.169-.118-.265-.304-.265-.497l.031-.19c.207-.604.208-.488 0-1.094l-.031-.191c0-.193.096-.379.265-.497.536-.372.466-.277.669-.885.084-.253.33-.424.605-.424h.002c.662.002.544.041 1.083-.338.112-.08.244-.119.376-.119s.264.039.376.118c.534.376.419.34 1.083.338h.002c.275 0 .521.171.605.424.203.607.132.513.67.885.168.118.264.304.264.497l-.031.191c-.207.604-.208.488 0 1.094zm-1.469-1.198l-.465-.464-1.41 1.446-.66-.627-.465.464 1.125 1.091 1.875-1.91zm8.5-4.349v14h-20v-14h20zm2-2h-24v18h24v-18zm-5 11h-14v1h14v-1zm0 2h-14v1h14v-1zm-7-19c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5-.672-1.5-1.5-1.5zm-4.74 5l2.771-1.979c-.206-.267-.36-.574-.446-.91l-4.045 2.889h1.72zm11.2 0l-4.044-2.889c-.086.336-.24.643-.446.91l2.77 1.979h1.72z" />{" "}
  </svg>
)
export const Cross = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 24 24"
  >
    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
  </svg>
)

export const Camera = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 24 24"
  >
    <path d="M5 4h-3v-1h3v1zm12 9c0 2.206-1.794 4-4 4s-4-1.794-4-4 1.794-4 4-4 4 1.794 4 4zm-1 0c0-1.657-1.342-3-3-3s-3 1.343-3 3 1.342 3 3 3 3-1.343 3-3zm8-8v17h-24v-17h5.93c.669 0 1.293-.334 1.664-.891l1.406-2.109h8l1.406 2.109c.371.557.995.891 1.664.891h3.93zm-19 4c0-.552-.447-1-1-1s-1 .448-1 1 .447 1 1 1 1-.448 1-1zm14 4c0-3.314-2.687-6-6-6s-6 2.686-6 6 2.687 6 6 6 6-2.686 6-6z" />
  </svg>
)

export const DropDown = ({ size }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={size}
    width={size}
    viewBox="0 0 24 24"
  >
    <path d="M12 21l-12-18h24z" />
  </svg>
)
