export const photos = [
  {
    src: "https://i.ibb.co/Vp7FmX8/home1.jpg",
    skb: false,
  },
  {
    src: "/images/SKB.jpeg",
    skb: true,
  },
  {
    src: "https://i.ibb.co/TTpHnGC/home2.jpg",
    skb: false,
  },
  {
    src: "https://i.ibb.co/yqvF9gQ/home3.jpg",
    skb: false,
  },
  {
    src: "https://i.ibb.co/jh5x3Vk/home4.jpg",
    skb: false,
  },

  {
    src: "https://i.ibb.co/YLQg3f7/home5.jpg",
    skb: false,
  },

  {
    src: "https://i.ibb.co/t49R0KP/home6.jpg",
    skb: false,
  },

  {
    src: "https://i.ibb.co/bmwkVJN/home7.jpg",
    skb: false,
  },
  {
    src: "https://i.ibb.co/vYM2nF4/home8.jpg",
    skb: false,
  },

  {
    src: "https://i.ibb.co/d2HqjD5/home9.jpg",
    skb: false,
  },
  {
    src: "https://i.ibb.co/FbR7396/home10.jpg",
    skb: false,
  },
  {
    src: "",
    skb: false,
  },

  {
    src: "https://i.ibb.co/xSrmxK8/home11.jpg",
    skb: false,
  },
]
